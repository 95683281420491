import React, {useContext, useEffect, useState} from 'react';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {Col, Row, Pagination, Button, Space} from 'antd';
import CommentList from '~src/components/molecules/CommentList';
import {LocaleContext} from '~src/context';
import isEmpty from 'ramda/es/isEmpty';

interface IContentProps {}

const PAGE_SIZE = 10;
const hasFalseOrEmpty = (obj: object) => Object.values(obj).every(item => !item) || isEmpty(obj);

const Content: React.FC<IContentProps> = () => {
  const {dispatch, comments} = useStoreon<IState, IEvents>('comments');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCommentsIds, setSelectedCommentsIds] = useState<{[key: string]: boolean}>({});
  const [removedCommentsIds, setRemovedCommentsIds] = useState<{[key: string]: boolean}>({});
  const {
    pages: {
      admin: {
        comments: {approveButton, deleteButton},
      },
    },
  } = useContext(LocaleContext);
  const handleClickAtApproveButton = () => {
    dispatch('comments/approve-comments', {
      commentsIds: Object.keys(selectedCommentsIds).filter(commentId => selectedCommentsIds[commentId]),
      approved: true,
    });
    setSelectedCommentsIds({});
  };
  const handleClickAtDeleteButton = () => {
    dispatch('comments/delete-comments', {
      commentsIds: Object.keys(selectedCommentsIds).filter(commentId => selectedCommentsIds[commentId]),
    });
    setSelectedCommentsIds({});
    setRemovedCommentsIds({...removedCommentsIds, ...selectedCommentsIds});
  };
  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };
  const handleSelectComment = (commentId: string, value: boolean) => {
    setSelectedCommentsIds({...selectedCommentsIds, [commentId]: value});
  };

  useEffect(() => {
    dispatch('comments/fetch-comments', {page: currentPage, count: PAGE_SIZE, orderBy: 'DESC', strategy: 'overwrite'});
  }, [currentPage]);

  const hasFalseIntoSelectedCommentsIds = hasFalseOrEmpty(selectedCommentsIds);

  return (
    <Row gutter={[0, 20]}>
      <Col span={24}>
        <CommentList
          selectedCommentsIds={selectedCommentsIds}
          removedCommentsIds={removedCommentsIds}
          comments={comments.values}
          mode={'editable'}
          onSelect={handleSelectComment}
        />
      </Col>
      <Col span={24}>
        <Row justify={'space-between'}>
          <Col>
            <Space>
              <Button type={'primary'} disabled={hasFalseIntoSelectedCommentsIds} onClick={handleClickAtApproveButton}>
                {approveButton}
              </Button>
              <Button disabled={hasFalseIntoSelectedCommentsIds} danger onClick={handleClickAtDeleteButton}>
                {deleteButton}
              </Button>
            </Space>
          </Col>
          <Col>
            {PAGE_SIZE < comments.count && (
              <Pagination
                showSizeChanger={false}
                defaultCurrent={currentPage}
                pageSize={PAGE_SIZE}
                total={comments.count}
                onChange={handleChangePage}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Content;

import React, {useContext} from 'react';
import {Col, Row, Space, Typography, Checkbox} from 'antd';
import moment from 'moment';
import {LocaleContext} from '~src/context';
import {IComment} from '~src/store/Comments';
import styled, {css} from 'styled-components';
import {customColors} from '~src/theme/colors';
import {formatDDMMYYYY, formatDDMMYYYYHHmm} from '~src/utils/date';
import {CheckboxChangeEvent} from 'antd/es/checkbox';

interface ICommentListProps {
  comments: IComment[];
  mode?: 'default' | 'editable';
  selectedCommentsIds?: {[key: string]: boolean};
  removedCommentsIds?: {[key: string]: boolean};
  onSelect?: (commentId: string, value: boolean) => void;
}

const CommentList: React.FC<ICommentListProps> = ({
  comments,
  selectedCommentsIds = {},
  removedCommentsIds = {},
  mode = 'default',
  onSelect,
}) => {
  const {
    maps: {
      comments: [notApprovedText, approvedText, deletedText],
    },
  } = useContext(LocaleContext);
  const datetimeFormat = mode === 'default' ? formatDDMMYYYY : formatDDMMYYYYHHmm;
  const isEditableMode = mode === 'editable';
  const getHandlerChangeCommentCheckbox = (commentId: string) => (e: CheckboxChangeEvent) => {
    onSelect && onSelect(commentId, e.target.checked);
  };

  return (
    <Row>
      {comments.map(comment => (
        <Col key={comment.commentId} span={24}>
          <CommentRowStyled
            accent={
              (isEditableMode && removedCommentsIds![comment.commentId] && 'red') ||
              (isEditableMode && comment.approved && 'green')
            }
          >
            {isEditableMode && (
              <LeftColStyled span={1}>
                {!removedCommentsIds![comment.commentId] && (
                  <Checkbox
                    onChange={getHandlerChangeCommentCheckbox(comment.commentId)}
                    checked={selectedCommentsIds[comment.commentId]}
                  />
                )}
              </LeftColStyled>
            )}
            <Col span={isEditableMode ? 23 : 24}>
              <Row gutter={[0, 10]}>
                <Col span={24}>
                  <Space size={15}>
                    <CommentUserName>{comment.userName}</CommentUserName>
                    <Typography.Text type={'secondary'}>
                      {moment(comment.createdAt).format(datetimeFormat)}
                    </Typography.Text>
                    {(isEditableMode && removedCommentsIds![comment.commentId] && (
                      <Typography.Text type={'danger'}>{deletedText}</Typography.Text>
                    )) ||
                      (isEditableMode && comment.approved && <ApprovedTextStyled>{approvedText}</ApprovedTextStyled>) ||
                      (isEditableMode && (
                        <Typography.Text type={'secondary'} mark>
                          {notApprovedText}
                        </Typography.Text>
                      ))}
                  </Space>
                </Col>
                <Col span={24}>
                  <Typography.Text>{comment.message}</Typography.Text>
                </Col>
              </Row>
            </Col>
          </CommentRowStyled>
        </Col>
      ))}
    </Row>
  );
};

const CommentRowStyled = styled(Row)<{accent: 'green' | 'red' | false}>`
  border-bottom: none;
  padding: 10px 0;
  ${props =>
    props.accent === 'green' &&
    css`
      background-color: ${customColors.green100};
    `}
  ${props =>
    props.accent === 'red' &&
    css`
      background-color: ${customColors.red100};
    `}
`;

const CommentUserName = styled(Typography.Text)`
  color: #52c41a;
`;

const LeftColStyled = styled(Col)`
  padding-left: 5px;
`;

const ApprovedTextStyled = styled(Typography.Text)`
  color: ${customColors.green500};
`;

export default CommentList;

import React, {useContext} from 'react';
import {LocaleContext} from '~src/context';
import locales from '~src/locales';

import GlobalStyle from '~src/theme/global-style';
import {ErrorBoundary, AdminTemplate} from '~src/components';
import Providers from '~src/components/Providers';
import Content from '~src/components/organisms/AdminOrganism/Comments/Content';

interface ICommentsPageProps {}

const CommentsPage: React.FC<ICommentsPageProps> = () => {
  const {
    pages: {admin},
  } = useContext(LocaleContext);
  return (
    <Providers>
      <ErrorBoundary>
        <LocaleContext.Provider value={locales}>
          <GlobalStyle />
          <AdminTemplate pageTitle={admin.comments.title}>
            <Content />
          </AdminTemplate>
        </LocaleContext.Provider>
      </ErrorBoundary>
    </Providers>
  );
};

export default CommentsPage;
